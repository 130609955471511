import type { ApiErrorMessage } from 'types/api'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

type GetLocalizedMessage = (apiErrorMessage: ApiErrorMessage, fallbackMsg?: string) => string

const useLocalizedErrorMessage = (): GetLocalizedMessage => {
  const { t } = useTranslation('errors')

  return useCallback<GetLocalizedMessage>(({ code, attributes }, fallbackMsg) => {
    if (code) {
      const message = t(code, { ...attributes })
      const fallback = fallbackMsg || t('defaultWithCode', { code }) || t('default')
      return message !== code ? message : fallback
    } else {
      return fallbackMsg || t('default')
    }
  }, [t])
}

export default useLocalizedErrorMessage
