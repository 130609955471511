import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { initialStateType, setManagerPayload } from './types'

const initialState: initialStateType = {
  managerInfo: {
    id: null,
    email: '',
    status: '',
    created: null,
    roles: [{ name: '' }],
  },
  isLoading: true,
}

export const managerSlice = createSlice({
  name: 'experiencesOverview',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setManagerInfo: (state, action: setManagerPayload) => {
      const { payload } = action
      state.managerInfo = payload.data
    },
  },
})

export const {
  setIsLoading,
  setManagerInfo,
} = managerSlice.actions
