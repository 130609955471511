import type { FC, ReactNode } from 'react'
import { useState } from 'react'
import classNames from 'classnames'
import arDown from 'assets/images/icons/ar-down.svg'
import './Accordion.scss'

type Props = {
  title: ReactNode,
  onClick?: () => void,
  className?: string,
  children?: ReactNode,
  opened?: boolean,
}

const Accordion: FC<Props> = ({ title, onClick, className, children, opened = false }) => {
  const [isOpen, setIsOpen] = useState(opened)
  const classname = classNames('Accordion', { 'Accordion--open': isOpen }, className)
  const toggleOpen = (): void => {
    setIsOpen((prevState) => !prevState)
  }

  return (
    <div className={classname}>
      <div className="Accordion__heading">
        <div className="title" onClick={onClick ? onClick : toggleOpen}>{title}</div>
        {children && (
          <div className="arrow" onClick={toggleOpen}><img src={arDown} alt="" /></div>
        )}
      </div>
      {children && (
        <div className="Accordion__body">{children}</div>
      )}
    </div>
  )
}

export default Accordion
