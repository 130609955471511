import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { debounce } from 'utils/debounce'
import arUp from 'assets/images/icons/ar-up.svg'

const ScrollToTopButton: FC = () => {
  const [showTopButton, setShowTopButton] = useState<boolean>(false)

  useEffect(() => {
    const listener = debounce(() => {
      if (window.scrollY > 1200) {
        setShowTopButton(true)
      } else {
        setShowTopButton(false)
      }
    }, 200)

    window.addEventListener('scroll', listener)
    return (): void => {
      window.removeEventListener('scroll', listener)
    }
  }, [])

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  const { t } = useTranslation()

  return (
    <div
      className={`scroll-to-top ${showTopButton ? 'show' : ''}`}
      onClick={scrollToTop}
    >
      <img src={arUp} alt={t('Scroll to top')} />
    </div>
  )
}

export default ScrollToTopButton
