import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type {
  initialStateType,
  setExperiencesOverviewPayload,
  setAccountListByOptionsPayload,
  setExperiencesOverviewFiltersPayload,
  setAccountListByOptionsFiltersPayload,
  setStatisticsPayload,
  setCompletedStatisticsPayload,
  Info,
} from './types'
import type { Account } from 'types/account'

const initialState: initialStateType = {
  getExperiencesOverviewState: {
    content: [],
    startDate: null,
    endDate: null,
    pagination: {
      page: 0,
      pageSize: 10,
      totalElements: 0,
      totalPages: 0,
    },
  },

  getAccountListByOptionsState: {
    roles: [''],
    keyword: '',
    statuses: [''],
    startDate: null,
    endDate: null,
    content: [],
    pagination: {
      page: 0,
      pageSize: 10,
      totalElements: 0,
      totalPages: 0,
    },
  },
  statistics: [],
  completedStatistics: [],
  isLoading: true,
}

export const experiencesOverviewSlice = createSlice({
  name: 'experiencesOverview',
  initialState,
  reducers: {
    setStatistics: (state, action: setStatisticsPayload) => {
      const { payload } = action
      state.statistics = payload.data
    },
    setCompletedStatistics: (state, action: setCompletedStatisticsPayload) => {
      const { payload } = action
      state.completedStatistics = payload.data
    },

    setExperiencesOverview: (state, action: setExperiencesOverviewPayload) => {
      const { payload } = action
      const filteredData: Info[] = []
      if (payload.data.content !== undefined) {
        payload.data.content.map((item) => {
          filteredData.push(item)
        })
      }
      state.getExperiencesOverviewState.content = filteredData
      state.getExperiencesOverviewState.pagination = {
        ...state.getExperiencesOverviewState.pagination,
        page: payload.data.page,
        pageSize: payload.data.pageSize,
        totalElements: payload.data.totalElements,
        totalPages: payload.data.totalPages,
      }
    },

    setExperiencesOverviewFilters: (
      state,
      action: setExperiencesOverviewFiltersPayload
    ) => {
      const { payload } = action
      if (payload.data.page !== undefined) {
        state.getExperiencesOverviewState.pagination.page = payload.data.page
      }
      if (payload.data.pageSize) {
        state.getExperiencesOverviewState.pagination.pageSize =
          payload.data.pageSize
      }
      if (payload.data.totalElements) {
        state.getExperiencesOverviewState.pagination.totalElements =
          payload.data.totalElements
      }
      if (payload.data.totalPages) {
        state.getExperiencesOverviewState.pagination.totalPages =
          payload.data.totalPages
      }
      if (payload.data.startDate !== undefined) {
        state.getExperiencesOverviewState.startDate = payload.data.startDate
      }
      if (payload.data.endDate !== undefined) {
        state.getExperiencesOverviewState.endDate = payload.data.endDate
      }
    },

    setAccountListByOptions: (
      state,
      action: setAccountListByOptionsPayload
    ) => {
      const { payload } = action
      const filteredData: Account[] = []
      if (payload.data.content !== undefined) {
        payload.data.content.map((item) => {
          filteredData.push(item)
        })
      }
      state.getAccountListByOptionsState.content = filteredData
      state.getAccountListByOptionsState.pagination = {
        ...state.getAccountListByOptionsState.pagination,
        page: payload.data.page,
        pageSize: payload.data.pageSize,
        totalElements: payload.data.totalElements,
        totalPages: payload.data.totalPages,
      }
    },

    setAccountListByOptionsFilters: (
      state,
      action: setAccountListByOptionsFiltersPayload
    ) => {
      const { payload } = action
      if (payload.data.page !== undefined) {
        state.getAccountListByOptionsState.pagination.page = payload.data.page
      }
      if (payload.data.pageSize) {
        state.getAccountListByOptionsState.pagination.pageSize =
          payload.data.pageSize
      }
      if (payload.data.totalElements) {
        state.getAccountListByOptionsState.pagination.totalElements =
          payload.data.totalElements
      }
      if (payload.data.totalPages) {
        state.getAccountListByOptionsState.pagination.totalPages =
          payload.data.totalPages
      }
      if (payload.data.startDate !== undefined) {
        state.getAccountListByOptionsState.startDate = payload.data.startDate
      }
      if (payload.data.endDate !== undefined) {
        state.getAccountListByOptionsState.endDate = payload.data.endDate
      }
      if (payload.data.keyword !== undefined) {
        state.getAccountListByOptionsState.keyword = payload.data.keyword
      }
      if (payload.data.role) {
        state.getAccountListByOptionsState.roles = payload.data.role
      }
      if (payload.data.statuses) {
        state.getAccountListByOptionsState.statuses = payload.data.statuses
      }
    },

    resetAccountList: (state) => {
      state.getAccountListByOptionsState = {
        ...state.getAccountListByOptionsState,
        roles: [''],
        statuses: [''],
        keyword: '',
        startDate: null,
        endDate: null,
      }
      state.getAccountListByOptionsState.pagination = {
        ...state.getAccountListByOptionsState.pagination,
        page: 0,
        pageSize: 10,
      }
    },
    resetExperiencesOverview: (state) => {
      state.getExperiencesOverviewState = {
        ...state.getExperiencesOverviewState,
        startDate: null,
        endDate: null,
      }
      state.getExperiencesOverviewState.pagination = {
        ...state.getExperiencesOverviewState.pagination,
        page: 0,
        pageSize: 10,
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

export const {
  setExperiencesOverview,
  setStatistics,
  setCompletedStatistics,
  setAccountListByOptions,
  resetAccountList,
  resetExperiencesOverview,
  setExperiencesOverviewFilters,
  setAccountListByOptionsFilters,
  setIsLoading,
} = experiencesOverviewSlice.actions
