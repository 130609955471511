import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getExperiencesOverview } from 'redux/features/admin/adminActions'
import {
  resetExperiencesOverview,
  setExperiencesOverview,
  setExperiencesOverviewFilters,
} from 'redux/features/admin/adminSlice'
import type { ExperiencesOverviewRequest } from 'redux/features/admin/types'
import type { ApiErrorData } from 'types/api'
import useShowToast from 'hooks/useShowToast'
import BasicTable from 'components/BasicTable/BasicTable'
import { COLUMNS } from './components/StatisticsColumns/columns'
import StatisticsFilter from './components/StatisticsFilter/StatisticsFilter'
import { useContextData } from 'pages/admin/AdminLayout/AdminLayout'
import './StatisticsPage.scss'

const StatisticsPage: FC = () => {
  const dispatch = useAppDispatch()
  const { content, pagination, endDate, startDate } = useAppSelector(
    (state) => state.admin.getExperiencesOverviewState
  )
  const columns = useMemo(() => COLUMNS, [])
  const experiencesData = useMemo(() => content, [content])
  const { page, pageSize, totalPages } = pagination
  const [loading, setLoading] = useState<boolean>(false)
  const showToast = useShowToast()
  const {
    eventId,
    setEventId,
    eventName,
    setEventName,
    activeEventSelect,
    setActiveEventSelect,
  } = useContextData()
  const pageNumber = useMemo(() => {
    return page
  }, [page])

  const changeEventNameHandler = (eventName: string): void => {
    setEventName(eventName)
  }

  const loadExperiencesOverview = useCallback((data: ExperiencesOverviewRequest): void => {
    setLoading(true)
    dispatch(getExperiencesOverview(data))
      .unwrap()
      .then((response) => {
        dispatch(setExperiencesOverview({ data: response }))
      })
      .catch((err: ApiErrorData) => {
        showToast({ type: 'error', error: err })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch])

  const getEventIdHandler = (eventId: number): void => {
    setEventId([eventId])
  }

  const showResultsHandler = (): void => {
    loadExperiencesOverview({
      eventIdSet: eventId,
      dateRange: {
        from: moment.utc(startDate).add(1, 'days').startOf('day').valueOf(),
        to: moment.utc(endDate).add(1, 'days').endOf('day').valueOf(),
      },
      page: page,
      pageSize: pageSize,
    })
  }

  const resetFilterHandler = (): void => {
    setEventId([])
    setEventName('Select Event')
    dispatch(resetExperiencesOverview())
    loadExperiencesOverview({
      page: 0,
      pageSize: 10,
    })
  }

  const setNextPage = (): void => {
    dispatch(setExperiencesOverviewFilters({ data: { page: pageNumber + 1 } }))
  }

  const setPrevPage = (): void => {
    dispatch(setExperiencesOverviewFilters({ data: { page: pageNumber - 1 } }))
  }

  const setPaginationPage = (page: number): void => {
    dispatch(setExperiencesOverviewFilters({ data: { pageSize: page } }))
  }

  useEffect(() => {
    if (eventId.length || endDate || startDate) {
      loadExperiencesOverview({
        page: page,
        pageSize: pageSize,
        dateRange: {
          from: moment.utc(startDate).add(1, 'days').startOf('day').valueOf(),
          to: moment.utc(endDate).add(1, 'days').endOf('day').valueOf(),
        },
        eventIdSet: eventId,
      })
    } else {
      loadExperiencesOverview({
        page: page,
        pageSize: pageSize,
      })
    }
  }, [endDate, eventId, loadExperiencesOverview, page, pageSize, startDate])

  return (
    <div className="StatisticsPage">
      <div className="StatisticsPage__container">
        <StatisticsFilter
          {...{
            showResultsHandler,
            getEventIdHandler,
            startDate,
            endDate,
            resetFilterHandler,
            eventName,
            changeEventNameHandler,
            activeEventSelect,
            setActiveEventSelect,
          }}
        />
        {content.length ? (
          <BasicTable
            eventId={eventId}
            pageNumber={pageNumber}
            pagesSize={pageSize}
            totalPages={totalPages}
            columns={columns}
            data={experiencesData}
            loading={loading}
            title="Experience list"
            neadPagination
            setNextPage={setNextPage}
            setPrevPage={setPrevPage}
            setPaginationPage={setPaginationPage}
          />
        ) : (
          <p>No data...</p>
        )}
      </div>
    </div>
  )
}

export default StatisticsPage
