import type { FC, ReactElement } from 'react'
import { useMemo } from 'react'
import type { Column } from 'react-table'
import { useTranslation } from 'react-i18next'
import type { IOverviewData } from './types'
import moment from 'moment'
import { useAppSelector } from 'redux/hooks'
import findLocale from 'utils/findLocale'
import './columns.scss'

interface EventCellProps {
  eventId: number
}

const EventCell: FC<EventCellProps> = ({ eventId }) => {
  const { events } = useAppSelector((state) => state.publicInfo)
  const { i18n } = useTranslation()
  const currentEvent = useMemo(() => {
    if (!events) {
      return null
    }

    return events.content.find(event => event.id === eventId) || null
  }, [eventId, events])
  const eventName = useMemo(() => {
    if (!currentEvent || !i18n.resolvedLanguage) {
      return ''
    }

    return findLocale(currentEvent.locales, i18n.resolvedLanguage).name
  }, [currentEvent, i18n.resolvedLanguage])
  return (
    <div className="eventInfo">
      {eventName}
    </div>
  )
}


export const COLUMNS: Column<IOverviewData>[] = [
  {
    Header: 'Email',
    accessor: 'email',
    Cell: ({ value }: {value: string}): ReactElement => {
      return <div className="email">{value}</div>
    },
  },
  {
    Header: 'Event',
    accessor: 'eventId',
    Cell: ({ value }: {value: number}): ReactElement => {
      return <EventCell eventId={value} />
    },
  },
  {
    Header: 'Started',
    accessor: 'created',
    Cell: ({ value }: { value: number }): ReactElement => {
      return <div className="date">{value && moment(value).format('DD/MM/YYYY HH:mm:ss')}</div>
    },
  },
  {
    Header: 'Completed',
    accessor: 'completed',
    Cell: ({ value }: { value: number | undefined }): ReactElement => {
      return <div className="date">{value && moment(value).format('DD/MM/YYYY HH:mm:ss')}</div>
    },
  },
  {
    Header: 'Claimed Count',
    accessor: 'claimedNftCount',
  },
  {
    Header: 'Minted Count',
    accessor: 'mintedNftCount',
  },
  {
    Header: 'First Claimed',
    accessor: 'firstClaimedNftOn',
    Cell: ({ value }: { value: number | undefined }): ReactElement => {
      return <div className="date">{value && moment(value).format('DD/MM/YYYY HH:mm:ss')}</div>
    },
  },
  {
    Header: 'First Minted',
    accessor: 'firstMintedNftOn',
    Cell: ({ value }: { value: number | undefined }): ReactElement => {
      return <div className="date">{value && moment(value).format('DD/MM/YYYY HH:mm:ss')}</div>
    },
  },
  {
    Header: 'Last Claimed',
    accessor: 'lastClaimedNftOn',
    Cell: ({ value }: { value: number | undefined }): ReactElement => {
      return <div className="date">{value && moment(value).format('DD/MM/YYYY HH:mm:ss')}</div>
    },
  },
  {
    Header: 'Last Minted',
    accessor: 'lastMintedNftOn',
    Cell: ({ value }: { value: number | undefined }): ReactElement => {
      return <div className="date">{value && moment(value).format('DD/MM/YYYY HH:mm:ss')}</div>
    },
  },
]
