import type { FC } from 'react'
import logo from 'assets/images/logo.svg'
import classNames from 'classnames'
import './Loader.scss'

interface LoaderProps {
  className?: string
}

const Loader: FC<LoaderProps> = ({ className }) => {
  return (
    <div className={classNames('Loader', className)}>
      <div className="Loader__circle" />
      <img src={logo} alt="Loading..." className="Loader__logo" />
    </div>
  )
}

export default Loader
