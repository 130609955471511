import './LinearProgress.scss'

import type { FC } from 'react'
import classNames from 'classnames'

interface LinearProgressProps {
  className?: string
}

const LinearProgress: FC<LinearProgressProps> = ({ className }) => {
  return (
    <div className={classNames('LinearProgress', className)}>
      <div className="LinearProgress__bar" />
    </div>
  )
}

export default LinearProgress
