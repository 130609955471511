import axios from 'api/axios'
import type { AxiosResponse } from 'axios'
import type {
  Partner,
  PartnersList,
  PartnersListRequest
} from 'types/partner'

const PartnerApi = {
  /**
   * API to search partners by optional criteria.
   * Security roles - Anybody.
   *
   * @param [data]
   * @param {number[]} [data.eventIdSet] - Available events id set.
   * @param {number} [data.page] - Page.
   * @param {number} [data.pageSize] - Page size.
   * @param {string} [data.lang] - Supported locale code.
   *
   * @return {PartnersList} - List of partners
   */
  async getPartnersList(data: PartnersListRequest = {}): Promise<AxiosResponse<PartnersList>> {
    return axios.post('/public/partners/list/page', data)
  },

  /**
   * API to retrieve partner’s details.
   * Security roles - Anybody.
   *
   * @param {number} id - Partner id.
   * @param {string} [lang] - Supported locale code.
   *
   * @return {Partner} - Partner info
   */
  async getPartnerInfo(id: number, lang?: string): Promise<AxiosResponse<Partner>> {
    return axios.get(`/public/partner/${id}`, { params: { lang } })
  },
}

export default PartnerApi
