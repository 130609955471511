import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { initialStateType, setMyExperiencesPayload } from './types'
import type { Nft } from 'types/entity'

const initialState: initialStateType = {
  myExperiences: null,
  experienceEntities: null,
  eventIdList: [],
  experienceStatus: undefined
}

export const myExperiencesSlice = createSlice({
  name: 'myExperiences',
  initialState,
  reducers: {
    setMyExperiences: (state, action: setMyExperiencesPayload) => {
      const { payload } = action
      let content = []

      if (payload.withReset) {
        content = payload.content
      } else {
        content = state.myExperiences?.content.length ?
          [...state.myExperiences.content, ...payload.content] :
          payload.content
      }

      state.myExperiences = { content, pagination: payload.pagination }
    },
    setExperienceStatus: (state, action) => {
      const { payload } = action
      if(!state.experienceStatus){
        state.experienceStatus = payload
      }
    },
    resetMyExperiences: (state) => {
      state.myExperiences = null
    },
    setExperienceEntities: (state, action: PayloadAction<{experienceId: number, list: Nft[]}>) => {
      if (!state.experienceEntities) {
        state.experienceEntities = { [action.payload.experienceId]: action.payload.list, }
      } else {
        state.experienceEntities[action.payload.experienceId] = action.payload.list
      }
    },
    setEventIdList: (state, action: PayloadAction<number[]>) => {
      state.eventIdList = action.payload
    },
  }
})

export const {
  setMyExperiences,
  resetMyExperiences,
  setExperienceStatus,
  setExperienceEntities,
  setEventIdList,
} = myExperiencesSlice.actions
