import type { RefObject } from 'react'
import { useEffect } from 'react'

const useClickAway = (onClickAway: () => void, ref: RefObject<HTMLElement>): void => {
  useEffect(() => {
    const clickAwayHandler = (event: MouseEvent): void => {
      if (!ref.current?.contains(event.target as Node)) {
        onClickAway()
      }
    }

    document.addEventListener('click', clickAwayHandler)

    return () => {
      document.removeEventListener('click', clickAwayHandler)
    }
  }, [onClickAway, ref])
}

export default useClickAway
