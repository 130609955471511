import type { FC } from 'react'
import Loader from 'components/Loaders/Loader'
import './FullScreenLoader.scss'

const FullScreenLoader: FC = () => {
  return (
    <div className="FullScreenLoader">
      <Loader />
    </div>
  )
}

export default FullScreenLoader
