import type { FC } from 'react'
import { useCallback, useRef } from 'react'
import useClickAway from 'hooks/useClickAway'
import EventsFilter from 'components/EventsFilter/EventsFilter'
import arDown from 'assets/images/icons/ar-down.svg'
import './EventFilterWrapper.scss'

interface EventFilterWrapperProps {
  getEventIdHandler?: (eventId: number) => void
  changeEventNameHandler?: (eventName: string) => void
  eventName?: string
  eventNameGraf?: string
  activeEventSelect?: boolean
  setActiveEventSelect?: (value: boolean) => void
}

const EventFilterWrapper: FC<EventFilterWrapperProps> = ({
  getEventIdHandler,
  eventName,
  eventNameGraf,
  changeEventNameHandler,
  activeEventSelect,
  setActiveEventSelect,
}) => {

  const changeActiveHandler = (): void => {
    setActiveEventSelect && setActiveEventSelect(!activeEventSelect)
  }

  const closeMenu = useCallback(() => {
    setActiveEventSelect && setActiveEventSelect(false)
  }, [setActiveEventSelect])

  const ref = useRef<HTMLDivElement>(null)
  useClickAway(closeMenu, ref)

  return (
    <div ref={ref} className={`accordion ${activeEventSelect ? 'active' : ''}`}>
      <div className="accordion__title" onClick={changeActiveHandler}>
        <span>{eventName || eventNameGraf}</span>
        <div className="accordion__icon">
          <img src={arDown} alt="" />
        </div>
      </div>
      <div className="accordion__content">
        <EventsFilter
          {...{
            changeEventNameHandler,
            getEventIdHandler,
            changeActiveHandler,
          }}
        />
      </div>
    </div>
  )
}

export default EventFilterWrapper
