import type { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { lazy } from 'react'
import withSuspense from 'components/WithSuspense'
const Header = withSuspense(lazy(() => import('components/Header')))
import './Layout.scss'

const Layout: FC = () => {
  return (
    <div className="Layout">
      <Header />
      <Outlet />
    </div>
  )
}

export default Layout
