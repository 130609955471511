import axios from 'api/axios'
import type { AxiosResponse } from 'axios'
import type {
  ExperienceStatisticsRequest,
  PageableRequestStatistics,
  ExperienceStatisticsList,
  IStatistics,
  ICompletedStatistics,
  AccountListByOptionsParams,
  Manager,
} from 'types/admin'
import type { AccountsList, SignUpParams } from 'types/account'

const AdminApi = {
  async getExperiencesOverview(
    data: ExperienceStatisticsRequest = {}
  ): Promise<AxiosResponse<ExperienceStatisticsList>> {
    const { signal } = data
    return axios.post('/statistics/admin/experiencesOverview', data, { signal })
  },
  /**
   * API to get Experiences Count.
   * Security roles - Admin.
   *
   * @param {string} interval - Supported statistic interval as path variable.
   * @param {PageableRequestStatistics} data
   * @param {number[]} data.eventIdSet - Array of id's
   * @param {number} data.dateRange - dateRange / from - mandatory range
   *
   * @return {IStatistics} - daily stats - from start date each point is a day. Range is 1month.
   */
  async getExperiencesStatistics(
    interval: string,
    data: PageableRequestStatistics = {}
  ): Promise<AxiosResponse<IStatistics[]>> {
    return axios.post(`/statistics/admin/experiencesCount/${interval}`, data)
  },
  /**
   * API to get Completed Experiences Count.
   * Security roles - Admin.
   *
   * @param {string} interval - Supported statistic interval as path variable.
   * @param {PageableRequestStatistics} data
   * @param {number[]} data.eventIdSet - Array of id's
   * @param {number} data.dateRange - dateRange / from - mandatory range
   *
   * @return {IStatistics} - daily stats - from start date each point is a day. Range is 1month.
   */
  async getCompletedExperiencesStatistics(
    interval: string,
    data: PageableRequestStatistics = {}
  ): Promise<AxiosResponse<ICompletedStatistics[]>> {
    return axios.post(
      `/statistics/admin/completedExperiencesPercentage/${interval}`,
      data
    )
  },

  /**
   * API to search for accounts by optional criteria.
   * Security roles - Admin.
   *
   */
  async getAccountListByOptions(data: AccountListByOptionsParams = {}): Promise<AxiosResponse<AccountsList>> {
    return axios.post('/account/list/page', data)
  },

  /**
   * API to register a manager.
   * Security roles - Admin.
   *
   * @param {SignUpParams} data
   * @param {string} data.email - User's email.
   * @param {string} [data.lang] - User's language.
   *
   * @return {Manager} - Manager's info.
   */
  async registerManager(data: SignUpParams): Promise<AxiosResponse<Manager>> {
    return axios.post('/account/manager', data)
  },
}

export default AdminApi
