export const debounce = (func: () => void, wait: number): () => void => {
  let timeout: ReturnType<typeof setTimeout>

  return (): void => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(func, wait)
  }
}
