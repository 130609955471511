import type { FC, ReactElement } from 'react'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import pickerIcon from 'assets/images/icons/pickerIcon.svg'
import './DatePickerComponent.scss'

interface DatePickerComponentProps {
  startDate?: Date | null
  endDate?: Date | null
  startDateChart?: Date | null
  setStartDateChart?: (value: Date | null) => void
  filterPeriodState?: string
  setStartDateHandler?: (value: Date | null) => void
  setEndDateHandler?: (value: Date | null) => void
  from?: string
}

const DatePickerComponent: FC<DatePickerComponentProps> = ({
  startDate,
  endDate,
  startDateChart,
  setStartDateChart,
  filterPeriodState,
  setStartDateHandler,
  setEndDateHandler,
  from
}) => {

  const CustomInput = ({
    value,
    onClick,
  }: {
    value: string
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  }, ref: React.Ref<HTMLDivElement>): ReactElement => (
    <div ref={ref} className="custom-input" onClick={onClick}>
      {value}
      <img src={pickerIcon} alt="" />
    </div>
  )

  return (
    <div className="DatePicker">
      <DatePicker
        className="DatePicker__fild"
        showMonthYearPicker={from === 'chart' && filterPeriodState === 'daily'}
        selected={startDate || startDateChart}
        onChange={(date): void => {
          setStartDateChart && setStartDateChart(date)
          setStartDateHandler && setStartDateHandler(date)
        }}
        dateFormat={from === 'chart' && filterPeriodState === 'daily'  ? 'MMMM yyyy' : 'd MMMM yyyy'}
        placeholderText="Date from"
        maxDate={new Date()}
        customInput={from === 'chart' && React.createElement(React.forwardRef(CustomInput))}
        isClearable={from !== 'chart'}
      />
      {from !== 'chart' && (
        <DatePicker
          className="DatePicker__fild"
          selected={endDate}
          onChange={(date): void => {
            setEndDateHandler && setEndDateHandler(date)
          } }
          dateFormat="d MMMM yyyy"
          placeholderText="Date to"
          isClearable
          maxDate={new Date()}
        />
      )}
    </div>
  )
}

export default DatePickerComponent
