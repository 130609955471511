import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import getToken from 'utils/getToken'
import { getAccount } from 'redux/features/user/userActions'
import type { Account } from 'types/account'

type AuthInfo = {
  user?: Account | null,
  isLoading: boolean,
  wasLoadedOnce: boolean,
}

export function useAuth(): AuthInfo {
  const dispatch = useAppDispatch()
  const {
    user,
    isLoading,
    wasLoadedOnce,
  } = useAppSelector((state) => state.user)
  const accessToken = getToken()

  useEffect(() => {
    dispatch(getAccount())
  }, [dispatch, accessToken])

  return { user, isLoading, wasLoadedOnce }
}
