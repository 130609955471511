import type { ComponentType, ReactElement } from 'react'
import { Suspense } from 'react'
import FullScreenLoader from 'components/Loaders/FullScreenLoader'

export default function withSuspense<P extends object>(Component: ComponentType<P>) {
  return function WithSuspense(props: P): ReactElement {
    return (
      <Suspense fallback={<FullScreenLoader />}>
        <Component {...props} />
      </Suspense>
    )
  }
}
