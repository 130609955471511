export enum ROLES {
  admin = 'ADMIN',
  member = 'MEMBER',
  manager = 'MANAGER',
  operator = 'OPERATOR',
}

export const ROLES_LABELS = {
  [ROLES.admin]: 'Admin',
  [ROLES.member]: 'Member',
  [ROLES.manager]: 'Manager',
  [ROLES.operator]: 'Operator',
}

export enum STATUSES {
  confirmed = 'confirmed',
  pending = 'pending',
}

export const DEFAULT_ROUTES: { [key: string]: string } = {
  [ROLES.admin]: '/admin/statistics',
  [ROLES.member]: '/my-experiences',
  [ROLES.operator]: '/partner'
}
