import { createAsyncThunk } from '@reduxjs/toolkit'
import AdminApi from 'api/adminApi'
import { isAxiosError } from 'axios'
import type { ExperiencesOverviewRequest, StatisticsRequest, AccountListByOptionsRequest } from './types'

export const getExperiencesOverview = createAsyncThunk(
  'getExperiencesOverview',
  async (data: ExperiencesOverviewRequest | undefined, { rejectWithValue }) => {
    try {
      const response = await AdminApi.getExperiencesOverview(data)
      return response.data
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  }
)

export const getStatistics = createAsyncThunk(
  'getStatistics',
  async (
    { interval, data }: { interval: string, data: StatisticsRequest | undefined, },
    { rejectWithValue }
  ) => {
    try {
      const response = await AdminApi.getExperiencesStatistics(interval, data)
      return response.data
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  }
)

export const getCompletedStatistics = createAsyncThunk(
  'getCompletedStatistics',
  async (
    { interval, data }: { interval: string, data: StatisticsRequest | undefined, },
    { rejectWithValue }
  ) => {
    try {
      const response = await AdminApi.getCompletedExperiencesStatistics(interval, data)
      return response.data
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  }
)

export const getAccountListByOptions = createAsyncThunk(
  'getAccountListByOptions',
  async (data: AccountListByOptionsRequest | undefined, { rejectWithValue }) => {
    try {
      const response = await AdminApi.getAccountListByOptions(data)
      return response.data
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  }
)
