import type { FC } from 'react'
import { lazy, useState } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'
import withSuspense from 'components/WithSuspense'
const Header = withSuspense(lazy(() => import('components/Header')))
import moment from 'moment'

type ContextType = {
  eventId: number[] | []
  eventIdGraf: number[] | []
  eventName: string
  eventNameGraf: string
  startDateChart: Date | null
  setEventId: (eventId: number[]) => void
  setEventIdGraf: (eventId: number[]) => void
  setStartDateChart: (value: Date | null) => void
  setEventName: (value: string) => void
  setEventNameGraf: (value: string) => void
  filterPeriodState: string
  setFilterPeriodState: (value: string) => void
  activeEventSelect: boolean
  setActiveEventSelect: (value: boolean) => void
}

const AdminLayout: FC = () => {
  const [eventId, setEventId] = useState<number[] | []>([])
  const [eventIdGraf, setEventIdGraf] = useState<number[] | []>([])
  const [eventName, setEventName] = useState('Select Event')
  const [eventNameGraf, setEventNameGraf] = useState('Select Event...')
  const date = new Date()
  const dateToUts = moment.utc(date).startOf('month').valueOf()
  const [startDateChart, setStartDateChart] = useState<Date | null>(
    new Date(dateToUts)
  )
  const [filterPeriodState, setFilterPeriodState] = useState<string>('daily')
  const [activeEventSelect, setActiveEventSelect] = useState<boolean>(false)

  return (
    <div className="AdminLayout">
      <Header />
      <Outlet
        context={{
          eventId,
          setEventId,
          startDateChart,
          setStartDateChart,
          eventName,
          setEventName,
          filterPeriodState,
          setFilterPeriodState,
          eventIdGraf,
          setEventIdGraf,
          eventNameGraf,
          setEventNameGraf,
          activeEventSelect,
          setActiveEventSelect
        }}
      />
    </div>
  )
}

const useContextData = (): ContextType => {
  return useOutletContext<ContextType>()
}

export { useContextData }
export default AdminLayout
