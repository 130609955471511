import axios from 'api/axios'
import type { AxiosResponse } from 'axios'
import type {
  EventInfo,
  EventsList,
  EventsListRequest,
  EventsOverviewList,
  EventsOverviewRequest,
  GetUpcomingEventsParams,
  UpcomingEventsList,
} from 'types/event'
import type { Lang } from 'types/api'

const EventApi = {
  /**
   * API to search events. Result is paged.
   * Actually active events are returned.
   * Security roles - Anybody.
   *
   * @param {string[]} [data.types] - Event types list
   * @param {number[]} [data.countryIdList] - Countries id list.
   * @param {number[]} [data.cityIdList] - Cities id list of the related country.
   * @param {number} [data.page] - Page.
   * @param {number} [data.pageSize] - Page size.
   * @param {DateRange} [data.dateRange] - Date filter
   * @param {string} [data.dateRange.from] - From timestamp
   * @param {string} [data.dateRange.to] - To timestamp
   * @param {string} [data.lang] - Supported locale code.
   *
   * @return {EventsList} - List of events
   */
  async getEventsList(data: EventsListRequest = {}): Promise<AxiosResponse<EventsList>> {
    return axios.post('/public/events/list/page', data)
  },

  /**
   * API to get brief public information about events. Result is paged.
   * Security roles - Anybody.
   * @param {number} [data.page] - Page.
   * @param {number} [data.pageSize] - Page size.
   * @param {string} [data.lang] - Supported locale code.
   *
   * @return {EventsOverviewList} - Events overview list
   */
  async getEventsOverview(data: EventsOverviewRequest = {}): Promise<AxiosResponse<EventsOverviewList>> {
    return axios.get('public/eventsOverview/list/page', { params: { ...data, promotions: data.promotions || 'landing' } })
  },

  /**
   * API to get event details.
   * Security roles - Anybody.
   *
   * @param {number} id - Event id
   * @param {Lang} [lang] - Supported locale code.
   *
   * @return {EventInfo} - Event info
   */
  async getEventById(id: number, lang?: Lang): Promise<AxiosResponse<EventInfo>> {
    return axios.get(`/public/event/${id}/details`, { params: { lang } })
  },

  /**
   * API to get event id by route.
   * Security roles - Anybody.
   *
   * @param {string} route - Event route
   *
   * @return {number} - Event id
   */
  async getRouteMapping(route: string): Promise<AxiosResponse<number>> {
    return axios.get(`/public/event/routeMapping`, { params: { route } })
  },

  /**
   * API to retrieve upcoming events for the user.
   * Geo filters applied when searching for the existing experiences can be used here as well or not.
   * Security roles - Member.
   *
   * @param {GetUpcomingEventsParams} [data]
   * @param {number[]} [data.countryIdList] - Countries filter.
   * @param {number[]} [data.cityIdList] - City filter.
   * @param {number} [data.page] - Page.
   * @param {number} [data.pageSize] - Page size.
   * @param {string} [data.lang] - User’s language.
   *
   * @return {UpcomingEventsList} - Experience detailed info.
   */
  async getUpcomingEvents(data: GetUpcomingEventsParams = {}): Promise<AxiosResponse<UpcomingEventsList>> {
    return axios.post('/my/upcomingEvents/list/page', data)
  },
}

export default EventApi
