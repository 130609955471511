import type { FC, ReactElement } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import FullScreenLoader from 'components/Loaders/FullScreenLoader'
import { DEFAULT_ROUTES } from 'constants/account'

type ProtectedRouteProps = {
  redirectPath?: string,
  role?: string,
  children: ReactElement,
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ redirectPath, role, children }) => {
  const location = useLocation()
  const { user, isLoading, wasLoadedOnce } = useAppSelector((state) => state.user)

  if (isLoading || (isLoading && !wasLoadedOnce)) {
    return <FullScreenLoader />
  }

  if (!isLoading && wasLoadedOnce && !user) {
    return <Navigate to={redirectPath || '/login'} replace state={{ from: location }} />
  }

  if (!isLoading && wasLoadedOnce && user && role) {
    const isRightRole = user.roles.find(r => r.name === role)

    return isRightRole ? children : <Navigate to={DEFAULT_ROUTES[user.roles[0].name] || '/login'} replace />
  }

  return children ? children : <Outlet />
}

export default ProtectedRoute
