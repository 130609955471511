import { createSlice } from '@reduxjs/toolkit'
import type { Nft } from 'types/entity'

type initialStateType = {
  mapScriptsReady: boolean,
  chosenEntity: Nft | null,
  eventCoordinates: {
    lat: number
    lng: number
  }
}

const initialState: initialStateType = {
  mapScriptsReady: false,
  chosenEntity: null,
  eventCoordinates: {
    lat: 0,
    lng: 0,
  },
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapScriptsReady(state, action) {
      state.mapScriptsReady = action.payload
    },
    setChosenEntity(state, action) {
      state.chosenEntity = action.payload
    },
    setOffice(state, action) {
      state.eventCoordinates = action.payload
    },
  },
})

export const { setOffice, setMapScriptsReady, setChosenEntity } = mapSlice.actions
