import { type ReactElement, useCallback } from 'react'
import { usePagination, useTable } from 'react-table'
import type { Column } from 'react-table'
import classNames from 'classnames'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import LinearProgress from 'components/Loaders/LinearProgress'
import './BasicTable.scss'

interface TableProps<T extends object> {
  data: T[]
  columns: Column<T>[]
  neadPagination?: boolean
  title: string
  pageNumber: number
  pagesSize: number
  totalPages?: number
  loading: boolean
  eventId?: number[]
  setNextPage?: () => void
  setPrevPage?: () => void
  setPaginationPage?: (value: number) => void
}

const BasicTable = <T extends object>({
  data,
  columns,
  neadPagination,
  title,
  pageNumber,
  pagesSize,
  totalPages,
  loading,
  setNextPage,
  setPrevPage,
  setPaginationPage,
}: TableProps<T>): ReactElement => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 5 },
      },
      usePagination
    )

  const pageNumbers = [5, 10, 15, 20]
  const pagesIndex = pageNumbers.findIndex(function (e) {
    return e === pagesSize
  })

  const canNextPage = totalPages && totalPages - 1 > pageNumber
  const canPreviousPage = pageNumber >= 1

  const handlePaginationClick = (size: number): void => {
    setPaginationPage && setPaginationPage(size)
  }

  const handlePreviousPage = useCallback(() => {
    setPrevPage && setPrevPage()
  }, [setPrevPage])

  const handleNextPage = useCallback(() => {
    setNextPage && setNextPage()
  }, [setNextPage])

  return (
    <div className="BasicTable">
      <div className="BasicTable__navigation">
        <p className="BasicTable__navigation--title">{title}</p>
        {neadPagination && (
          <div className="BasicTable__control">
            <div className="BasicTable__control--nextAndPrev">
              <button onClick={handlePreviousPage} disabled={!canPreviousPage}>
                <MdKeyboardArrowLeft size={20} />
              </button>{' '}
              {<p>Page {pageNumber !== undefined && pageNumber + 1}</p>}
              <button onClick={handleNextPage} disabled={!canNextPage}>
                <MdKeyboardArrowRight size={20} />
              </button>{' '}
            </div>
            <nav>
              <ul className="pagination">
                {pageNumbers.map((size, i) => (
                  <button
                    key={size}
                    className={classNames('pagination-button', { 'page-item-active': i === pagesIndex, })}
                    onClick={(): void => {
                      handlePaginationClick(size)
                    }}
                  >
                    {' '}
                    {size}
                  </button>
                ))}
              </ul>
            </nav>
          </div>
        )}
      </div>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, ind) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`row-${ind}`}>
              {headerGroup.headers.map((column, ind2) => (
                <th {...column.getHeaderProps()} key={`column-${ind2}`}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, ind) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={`row2-${ind}`}>
                {row.cells.map((cell, ind2) => {
                  return (
                    <td
                      data-label={cell.column.Header}
                      {...cell.getCellProps()}
                      key={`cell-${ind2}`}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {loading && <LinearProgress />}
    </div>
  )
}

export default BasicTable
