import type { FC } from 'react'
import { useAppDispatch } from 'redux/hooks'
import { setExperiencesOverviewFilters } from 'redux/features/admin/adminSlice'
import DatePickerComponent from '../../../components/DatePicker/DatePickerComponent'
import EventFilterWrapper from '../EventFilterWrapper/EventFilterWrapper'
import './StatisticsFilter.scss'

interface StatisticsFilterProps {
  getEventIdHandler: (eventId: number) => void
  showResultsHandler: () => void
  startDate: Date | null
  endDate: Date | null
  resetFilterHandler: () => void
  changeEventNameHandler: (eventName: string) => void
  eventName: string
  activeEventSelect: boolean
  setActiveEventSelect: (value: boolean) => void
}

const StatisticsFilter: FC<StatisticsFilterProps> = ({
  getEventIdHandler,
  showResultsHandler,
  startDate,
  endDate,
  resetFilterHandler,
  changeEventNameHandler,
  eventName,
  activeEventSelect,
  setActiveEventSelect,
}) => {
  const dispatch = useAppDispatch()

  const setStartDateHandler = (date: Date | null): void => {
    dispatch(setExperiencesOverviewFilters({ data: { startDate: date } }))
  }

  const setEndDateHandler = (date: Date | null): void => {
    dispatch(setExperiencesOverviewFilters({ data: { endDate: date } }))
  }

  return (
    <>
      <div className="StatisticsFilter">
        <EventFilterWrapper
          {...{
            eventName,
            changeEventNameHandler,
            getEventIdHandler,
            activeEventSelect,
            setActiveEventSelect,
          }}
        />
        <DatePickerComponent {...{ startDate, endDate, setStartDateHandler, setEndDateHandler }} />
        <button className="button" onClick={showResultsHandler}>Show results</button>
        <button className="button" onClick={(): void => resetFilterHandler()}>Reset</button>
      </div>
      <hr />
    </>
  )
}

export default StatisticsFilter
