import type { ToastOptions, TypeOptions } from 'react-toastify'
import type { ApiErrorData } from 'types/api'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import useLocalizedErrorMessage from './useLocalizedErrorMessage'

type ShowToastParams = {
  type: TypeOptions
  text?: string
  error?: ApiErrorData
  options?: ToastOptions
}

type ShowToast = (params: ShowToastParams) => void

const defaultOptions: ToastOptions = {
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
}

const useShowToast = (): ShowToast => {
  const { t } = useTranslation('errors')
  const getLocalizedMessage = useLocalizedErrorMessage()

  const getErrorMessages = useCallback((error: ShowToastParams['error']): string[] => {
    if (error?.messages?.length) {
      return error.messages.map(msg => getLocalizedMessage(msg))
    }
    return []
  }, [getLocalizedMessage]);

  return useCallback<ShowToast>(({
    text,
    error,
    type = 'default',
    options = {}
  }) => {
    const toastOptions: ToastOptions = { ...defaultOptions, ...options }
    switch (type) {
      case 'info':
        toast.info(text, toastOptions)
        break
      case 'success':
        toast.success(text, toastOptions)
        break
      case 'warning':
        toast.warning(text, toastOptions)
        break
      case 'error': {
        const messages = getErrorMessages(error)
        if (messages.length) {
          messages.forEach(msg => toast.error(msg, toastOptions))
        } else {
          toast.error(text || t('default'), toastOptions)
        }
        break
      }
      default: toast(text, toastOptions)
    }}, [getErrorMessages, t])
}

export default useShowToast
