import { createAsyncThunk } from '@reduxjs/toolkit'
import EventApi from 'api/eventApi'
import PartnerApi from 'api/partnerApi'
import UtilityApi from 'api/utilityApi'
import type { Lang } from 'types/api'
import type { Country, SupportedLocale } from 'types/utilityApi'
import type {
  EventInfo,
  EventOverview,
  EventsList,
  EventsListRequest,
  EventsOverviewList,
  EventsOverviewRequest
} from 'types/event'
import type { PartnersList } from 'types/partner'
import { isAxiosError } from 'axios'
import { setEvents, setEventsOverview, setPartners } from './publicInfoSlice'
import type { getPartnersListPayload } from './types'
import { range } from 'utils/range'

export const getEventsList  = createAsyncThunk(
  'publicInfo/getEvents',
  async (data: EventsListRequest | undefined, { rejectWithValue }) => {
    try {
      const response = await EventApi.getEventsList(data)
      return response.data as EventsList
    } catch(error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  }
)

export const getEventsOverview = createAsyncThunk(
  'publicInfo/getEventsOverview',
  async (data: EventsOverviewRequest | undefined, { rejectWithValue }) => {
    try {
      const response = await EventApi.getEventsOverview(data)
      return response.data as EventsOverviewList
    } catch(error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  }
)

export const getAllEventsOverview = createAsyncThunk(
  'publicInfo/getAllEventsOverview',
  async (data: EventsOverviewRequest | undefined, { rejectWithValue, dispatch }) => {
    try {
      const pageSize = 100
      const overviewList: EventOverview[] = []

      const firstResponse = await dispatch(getEventsOverview({ ...data, page: 0 })).unwrap()
      overviewList.push(...firstResponse.content)

      if (firstResponse.totalPages > 1) {
        const pagesRange = range(1, firstResponse.totalPages)
        await Promise.all(pagesRange.map(page => dispatch(getEventsOverview({ ...data, page, pageSize })) ))
          .then((pages) => {
            pages.forEach((page: any) => overviewList.push(...page.payload.content))
          })
      }

      dispatch(setEventsOverview({ events: overviewList }))

      return overviewList
    } catch(error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  }
)
export const getAllEvents = createAsyncThunk(
  'publicInfo/getAllEvents',
  async (lang: string, { rejectWithValue, dispatch }) => {
    try {
      const pageSize = 100
      const eventsList: EventInfo[] = []

      const firstResponse = await dispatch(getEventsList({ page: 0, pageSize, lang })).unwrap()
      eventsList.push(...firstResponse.content)

      if (firstResponse.totalPages > 1) {
        const pagesRange = range(1, firstResponse.totalPages)
        await Promise.all(pagesRange.map(page => dispatch(getEventsList({ page, pageSize, lang })) ))
          .then((pages) => {
            pages.forEach((page: any) => eventsList.push(...page.payload.content))
          })
      }

      dispatch(setEvents({
        content: eventsList,
        pagination: {
          page: firstResponse.totalPages - 1,
          pageSize,
          totalPages: firstResponse.totalPages,
          totalElements: firstResponse.totalElements,
        },
        withReset: true,
      }))

      return eventsList
    } catch(error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  }
)

export const getCountriesList = createAsyncThunk(
  'publicInfo/getCountriesList',
  async (lang: Lang | undefined, { rejectWithValue }) => {
    try {
      const response = await UtilityApi.getCountriesList(lang)
      return response.data as Country[]
    } catch(error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  }
)

export const getPartnersList  = createAsyncThunk(
  'publicInfo/getPartners',
  async (data: getPartnersListPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await PartnerApi.getPartnersList({ ...data?.fetchData, pageSize: 100 })
      const { page, pageSize, totalElements, totalPages, content } = response.data

      dispatch(setPartners({
        pagination: { page, pageSize, totalPages, totalElements },
        content,
        withReset: data?.withReset,
      }))

      return response.data as PartnersList
    } catch(error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  }
)


export const getLocalesList = createAsyncThunk(
  'publicInfo/getLocalesList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UtilityApi.getLocalesList()
      return response.data as SupportedLocale[]
    } catch(error: unknown) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }

      return rejectWithValue(error)
    }
  }
)



